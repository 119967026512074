import React from "react";
import {inject, Observer} from 'mobx-react'
import Header from "./Header";
import Footer from "./Footer";
import DarkModeButton from "./DarkModeButton";
import TestimonialContent from "./TestimonialContent";


const TestimonialComponent = (props) => {

    return (
        <Observer>
            {() => (
                <div className={`${props.baseStore.darkMode ? 'parent-dark' : 'parent'}`}>

                    <div className="app flex flex-column">

                        <div className="flex" style={{flex: 1}}>
                            <Header navigationText="Home" navigationPath="/home"/>
                        </div>

                        <div className="flex" style={{flex: 10}}>
                            <TestimonialContent/>
                        </div>

                        <div className="flex flex-column" style={{flex: 1}}>
                            <DarkModeButton/>
                            <Footer/>
                        </div>

                    </div>
                </div>
            )}</Observer>
    );
}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(TestimonialComponent);

