import React from "react";
import '../App.css';
import linkedin from "../images/linkedin.png";
import fiverr from "../images/fiverr.png";
import github from "../images/github.png";
import cv from "../images/cv.png"
import email from "../images/email.png"
import CommunicationButton from "./CommunicationButton";

const CommunicationChannels = (props) => {

    return (
        <div className="flex flex-row align-items-start padding-md full-width">
            <CommunicationButton dark={props.dark} link={"https://www.linkedin.com/in/sparshsri/"} src={linkedin}
                                 alt={"LinkedIn"}/>
            <CommunicationButton dark={props.dark} link={"https://www.fiverr.com/sparshsri/"} src={fiverr}
                                 alt={"Fiverr"}/>
            <CommunicationButton dark={props.dark} link={"https://github.com/reallyinvincible/"} src={github}
                                 alt={"Github"}/>
            <CommunicationButton dark={props.dark} link={"mailto:mailtosparshsri@gmail.com"} src={email}
                                 alt={"Email"}/>
            <CommunicationButton dark={props.dark} link={"https://drive.google.com/file/d/1vnPSCFcCR7gM-uLK0sF5pHEOJysoCVZj/view?usp=sharing"} src={cv}
                                 alt={"CV"} />
        </div>
    );

}

export default CommunicationChannels