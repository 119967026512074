import React from 'react'
import 'ui-neumorphism/dist/index.css'

const IntroductionText = () => {

    const nameSpanStyle = {
        color: '#00d8ff'
    }
    const androidSpanStyle = {
        color: '#3DDC84'
    }

    return (
        <div className="flex flex-column align-items-start padding-md full-width">
            <div className="intro-text-title">
                Hi, I'm <span style={nameSpanStyle}>Sparsh Srivastava</span>
            </div>
            <div className="intro-text-subtitle">
                Google Associate <span style={androidSpanStyle}>Android Developer</span> based in India.
            </div>
        </div>
    );
}

export default IntroductionText