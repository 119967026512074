import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import RootStore from "./stores/RootStore";
import {Provider} from "mobx-react";

import './index.css';
import App from './App';
import Navigation from "./utils/Navigation";

render(
    <BrowserRouter
        ref={(navRef) => {
            Navigation.setNavigator(navRef);
        }}
    >
        <Provider rootStore={RootStore}>
            <App/>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
