import React from "react";
import {IconButton} from "ui-neumorphism";
import {Tooltip} from 'ui-neumorphism'

const CommunicationButton = (props) => {

    return (
        <div>
            <Tooltip
                dark={props.dark}
                className='ma-3'
                content={props.alt}>
                <IconButton className="communication-buttons" dark={props.dark} onClick={() => {
                    window.open(props.link, "_blank")
                }} text={false} size='large' color='var(--warning)'>
                    <img className="communication-buttons-image" src={props.src} alt={props.alt}/>
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default CommunicationButton