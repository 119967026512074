import './App.css';
import {inject} from "mobx-react";
import {Route, Switch, Redirect} from 'react-router-dom';
import HomeContent from "./components/HomeComponent";
import TestimonialComponent from "./components/TestimonialComponent";

const App = (props) => {
    return (
        <Switch>
            <Route {...props} exact path="/home" component={HomeContent}/>
            <Route {...props} exact path="/testimonials" component={TestimonialComponent}/>
            <Redirect  {...props} from="/" to="/home"/>
        </Switch>
    );
}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(App);
