import React from "react";

const FooterContent = () => {

    return (
        <div className="footer-text padding-md">
            © 2021 Sparsh Srivastava. All rights reserved
        </div>
    );

}

export default FooterContent