import React from "react";
import {Divider} from "ui-neumorphism";
import {inject, Observer} from 'mobx-react'
import FooterContent from "./FooterContent";

const Footer = (props) => {
    return (
        <Observer>
            {() => (
                <div style={{flex: 1}}>
                    <Divider dark={props.baseStore.darkMode}/>
                    <FooterContent dark={props.baseStore.darkMode}/>
                </div>
            )}</Observer>
    );
}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(Footer);

